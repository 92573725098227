// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-enquiries-js": () => import("./../../../src/pages/enquiries.js" /* webpackChunkName: "component---src-pages-enquiries-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sample-menus-js": () => import("./../../../src/pages/sample-menus.js" /* webpackChunkName: "component---src-pages-sample-menus-js" */),
  "component---src-pages-venues-js": () => import("./../../../src/pages/venues.js" /* webpackChunkName: "component---src-pages-venues-js" */),
  "component---src-templates-dynamic-template-js": () => import("./../../../src/templates/DynamicTemplate.js" /* webpackChunkName: "component---src-templates-dynamic-template-js" */)
}

